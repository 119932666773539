<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card no-body>
      <b-card-body>

        <div class="row">
          <div class="col-md-3">
            <div class="row">
              <!-- <div class="col-md-6">
                <button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  class="btn-1"
                  block
                  @click="getwinloss('Today')"
                >
                  วันนี้
                </button>
              </div> -->
              <div class="col-md-6 mb-1">
                <button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  class="btn-1"
                  block
                  @click="getwinloss('Yesterday')"
                >
                  เมื่อวาน
                </button>
              </div>
              <div class="col-md-6 mb-1">
                <button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  class="btn-1"
                  block
                  @click="getwinloss('ThisWeek')"
                >
                  อาทิตย์นี้
                </button>
              </div>
              <div class="col-md-6 mb-1">
                <button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  class="btn-1"
                  block
                  @click="getwinloss('LastWeek')"
                >
                  อาทิตย์ที่แล้ว
                </button>
              </div>
              <div class="col-md-6 mb-1">
                <button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  class="btn-1"
                  block
                  @click="getwinloss('ThisMonth')"
                >
                  เดือนนี้
                </button>
              </div>
              <div class="col-md-6 mb-1">
                <button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  class="btn-1"
                  block
                  @click="getwinloss('LastMonth')"
                >
                  เดือนที่แล้ว
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-8">

                <b-form-group>
                  <flat-pickr
                    v-model="rangeDate"
                    placeholder="เลือกวันที่"
                    class="form-control"
                    :config="{
                      mode: 'range',
                      enableTime: true,
                      dateFormat: 'Y-m-d',
                      time_24hr: true,
                      defaultHour: 0,
                    }"
                  />
                </b-form-group>
              </div>
              <div class="col-md-4">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-icon"
                  block
                  @click="RangDateCal()"
                >
                  <feather-icon icon="SearchIcon" />
                  Search
                </b-button>
              </div>
            </div>
          </div>
          <div class="col-md-9">
            <b-form-group
              class="mr-1 mb-md-0"
            >

              <!-- <b-form-checkbox
                v-model="allSelected"
                :indeterminate="indeterminate"
                aria-describedby="flavours"
                aria-controls="flavours"
                @change="toggleAll"
              >
                ทั้งหมด
              </b-form-checkbox> -->
              <b-form-checkbox-group
                id="checkbox-group-1"
                v-model="selected"
                :options="options"
                value-field="name"
                text-field="name"
                name="flavour-1"
                class="demo-inline-spacing"
                @change="getwinloss()"
              />
            </b-form-group>
            <!-- Selected: <strong>{{ selected }}</strong> -->
          </div>
        </div>

      </b-card-body>
      <b-card no-body>
        <table
          id="zero_config"
          class="table table-bordered table-sm table-striped table-hover"
        >
          <thead>
            <tr class="text-center">
              <th
                rowspan="2"
                class="align-middle"
              >
                #
              </th>
              <th
                rowspan="2"
                class="align-middle"
              >
                Username
              </th>
              <th
                rowspan="2"
                class="align-middle"
                style=""
              >
                Turnover
              </th>
              <th
                rowspan="2"
                class="align-middle"
                style=""
              >
                Valid Amount
              </th>
              <th
                rowspan="2"
                class="align-middle"
                style=""
              >
                Stake Count
              </th>
              <th
                rowspan="2"
                class="align-middle"
                style=""
              >
                Players
              </th>
              <th
                colspan="3"
                class="align-middle"
              >
                Member
              </th>
              <th
                colspan="3"
                class="align-middle bg-warning"
              >
                Agent
              </th>
              <th
                colspan="3"
                class="align-middle"
              >
                Company
              </th>
            </tr>
            <tr class="text-center">
              <th style="">
                W/L
              </th>
              <th style="">
                Com
              </th>
              <th style="">
                Total
              </th>
              <th
                style=""
                class="bg-warning"
              >
                W/L
              </th>
              <th
                style=""
                class="bg-warning"
              >
                Com
              </th>
              <th
                style=""
                class="bg-warning"
              >
                Total
              </th>
              <th style="">
                W/L
              </th>
              <th style="">
                Com
              </th>
              <th style="">
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(data,index) in listdata"
              :key="data"
              class="text-center font-weight-bolder"
            >
              <td>{{ index + 1 }}</td>
              <td>{{ data.username }}</td>
              <td>{{ data.turnover.toFixed(2) }}</td>
              <td>{{ data.validAmount.toFixed(2) }}</td>
              <td>{{ data.stake_count }}</td>
              <td>{{ data.member_count }}</td>
              <td>{{ data.member_wl.toFixed(2) }}</td>
              <td>{{ data.member_com.toFixed(2) }}</td>
              <td>{{ data.member_total.toFixed(2) }}</td>
              <td>{{ data.agent_wl.toFixed(2) }}</td>
              <td>{{ data.agent_com.toFixed(2) }}</td>
              <td>{{ data.agent_total.toFixed(2) }}</td>
              <td>{{ data.company_wl.toFixed(2) }}</td>
              <td>{{ data.company_com.toFixed(2) }}</td>
              <td>{{ data.company_total.toFixed(2) }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr class="text-right font-weight-bold bg-dark text-white">
              <td
                colspan="2"
                class="text-right"
              >
                ทั้งหมด:
              </td>
              <td> <span class="text-center font-weight-bolder">
                {{ listdata.reduce((total, item) => total + item.turnover, 0).toFixed(2) }}<span />
              </span>
              </td>
              <td> <span class=" Simple">
                     {{ listdata.reduce((total, item) => total + item.validAmount, 0).toFixed(2) }}
                     <span
                       style="text-decoration: underline; cursor:pointer;"
                       onclick="if (!window.__cfRLUnblockHandlers) return false; changeFull($(this));"
                     />
                   </span>
                <span
                  class=" Full"
                  style="display:none;"
                >0.0000</span>
              </td>
              <td>{{ listdata.reduce((total, item) => total + item.stake_count, 0) }}</td>
              <td>{{ listdata.reduce((total, item) => total + item.member_count, 0) }}</td>
              <td> <span class=" Simple">
                     {{ listdata.reduce((total, item) => total + item.member_wl, 0).toFixed(2) }}
                   </span>
                <span
                  class=" Full"
                  style="display:none;"
                >0.0000</span>
              </td>
              <td> <span class=" Simple">
                     {{ listdata.reduce((total, item) => total + item.member_com, 0).toFixed(2) }}
                   </span>
                <span
                  class=" Full"
                  style="display:none;"
                >0.0000</span>
              </td>
              <td> <span class=" Simple">
                     {{ listdata.reduce((total, item) => total + item.member_total, 0).toFixed(2) }}
                   </span>
                <span
                  class=" Full"
                  style="display:none;"
                >0.0000</span>
              </td>
              <td> <span class=" Simple">
                     {{ listdata.reduce((total, item) => total + item.agent_wl, 0).toFixed(2) }}
                   </span>
                <span
                  class=" Full"
                  style="display:none;"
                >0.0000</span>
              </td>
              <td> <span class=" Simple">
                     {{ listdata.reduce((total, item) => total + item.agent_com, 0).toFixed(2) }}
                   </span>
                <span
                  class=" Full"
                  style="display:none;"
                >0.0000</span>
              </td>
              <td> <span class=" Simple">
                     {{ listdata.reduce((total, item) => total + item.agent_total, 0).toFixed(2) }}
                   </span>
                <span
                  class=" Full"
                  style="display:none;"
                >0.0000</span>
              </td>
              <td> <span class=" Simple">
                     {{ listdata.reduce((total, item) => total + item.company_wl, 0).toFixed(2) }}
                   </span>
                <span
                  class=" Full"
                  style="display:none;"
                >0.0000</span>
              </td>
              <td> <span class=" Simple">
                     {{ listdata.reduce((total, item) => total + item.company_com, 0).toFixed(2) }}
                   </span>
                <span
                  class=" Full"
                  style="display:none;"
                >0.0000</span>
              </td>
              <td> <span class=" Simple">
                     {{ listdata.reduce((total, item) => total + item.company_total, 0).toFixed(2) }}
                   </span>
                <span
                  class=" Full"
                  style="display:none;"
                >0.0000</span>
              </td>
            </tr>
          </tfoot>
        </table>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0 mt-1">
          <!-- page length -->
          <b-form-group
            label="Per Page"
            label-cols="8"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>
          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-card>
  </b-overlay>
</template>

<script>
// import vSelect from 'vue-select'
// import moment from 'moment-timezone'
import {
  // BRow,
  // BCol,
  // BFormDatepicker,
  // BFormCheckbox,
  BFormCheckboxGroup,
  BOverlay,
  BIconController,
  BCard,
  //  BAvatar,
  BFormGroup,
  BFormSelect,
  BPagination,
  // BFormInput,
  BButton,
  BCardBody,
  VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required, email } from '@validations'
import flatPickr from 'vue-flatpickr-component'
// import moment from 'moment-timezone'

export default {
  components: {
    // BRow,
    // BCol,
    // vSelect,
    // BFormCheckbox,
    BFormCheckboxGroup,
    BCard,
    // BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    // BFormInput,
    // BInputGroupAppend,
    BButton,
    BCardBody,
    BOverlay,
    BIconController,
    // BFormDatepicker,
    flatPickr,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      allSelected: false,
      selected: [],
      indeterminate: false,
      start: null,
      end: null,
      // selected: 'Today',
      // selectedOp: [
      //   { title: 'Today', value: 'Today' },
      //   { title: 'Yesterday', value: 'Yesterday' },
      //   { title: 'This Week', value: 'ThisWeek' },
      //   { title: 'Last Week', value: 'LastWeek' },
      //   { title: 'This Month', value: 'ThisMonth' },
      //   { title: 'Last Month', value: 'LastMonth' },
      // ],
      emailValue: '',
      week_day: '',
      time: null,
      time2: null,
      name: '',
      required,
      email,
      search_val: null,
      show: false,
      rangeDate: null,
      depositdata: [],
      member: '',
      amount: '',
      optionsMember: '',
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      week: [
        {
          value: 'today',
          text: 'to day',
        },
      ],
      timeOp1: [
        { value: '00', text: '00' },
        { value: '01', text: '01' },
        { value: '02', text: '02' },
        { value: '03', text: '03' },
        { value: '04', text: '04' },
        { value: '05', text: '05' },
        { value: '06', text: '06' },
        { value: '07', text: '07' },
        { value: '08', text: '08' },
        { value: '09', text: '09' },
        { value: '10', text: '10' },
        { value: '11', text: '11' },
        { value: '12', text: '12' },
        { value: '13', text: '13' },
        { value: '14', text: '14' },
        { value: '15', text: '15' },
        { value: '16', text: '16' },
        { value: '17', text: '17' },
        { value: '18', text: '18' },
        { value: '19', text: '19' },
        { value: '20', text: '20' },
        { value: '21', text: '21' },
        { value: '22', text: '22' },
        { value: '23', text: '23' },
      ],
      timeOp2: [
        { value: '00', text: '00' },
        { value: '01', text: '01' },
        { value: '02', text: '02' },
        { value: '03', text: '03' },
        { value: '04', text: '04' },
        { value: '05', text: '05' },
        { value: '06', text: '06' },
        { value: '07', text: '07' },
        { value: '08', text: '08' },
        { value: '09', text: '09' },
        { value: '10', text: '10' },
        { value: '11', text: '11' },
        { value: '12', text: '12' },
        { value: '13', text: '13' },
        { value: '14', text: '14' },
        { value: '15', text: '15' },
        { value: '16', text: '16' },
        { value: '17', text: '17' },
        { value: '18', text: '18' },
        { value: '19', text: '19' },
        { value: '20', text: '20' },
        { value: '21', text: '21' },
        { value: '22', text: '22' },
        { value: '23', text: '23' },
        { value: '24', text: '24' },
        { value: '25', text: '25' },
        { value: '26', text: '26' },
        { value: '27', text: '27' },
        { value: '28', text: '28' },
        { value: '29', text: '29' },
        { value: '30', text: '30' },
        { value: '31', text: '31' },
        { value: '32', text: '32' },
        { value: '33', text: '33' },
        { value: '34', text: '34' },
        { value: '35', text: '35' },
        { value: '36', text: '36' },
        { value: '37', text: '37' },
        { value: '38', text: '38' },
        { value: '39', text: '39' },
        { value: '40', text: '40' },
        { value: '41', text: '41' },
        { value: '42', text: '42' },
        { value: '43', text: '43' },
        { value: '44', text: '44' },
        { value: '45', text: '45' },
        { value: '46', text: '46' },
        { value: '47', text: '47' },
        { value: '48', text: '48' },
        { value: '49', text: '49' },
        { value: '50', text: '50' },
        { value: '51', text: '51' },
        { value: '52', text: '52' },
        { value: '53', text: '53' },
        { value: '54', text: '54' },
        { value: '55', text: '55' },
        { value: '56', text: '56' },
        { value: '57', text: '57' },
        { value: '58', text: '58' },
        { value: '59', text: '59' },
      ],
      fields: [
        { key: 'index', label: 'no.' },
        { key: 'username', label: 'username' },
        { key: 'turnover', label: 'turnover' },
        { key: 'winloss', label: 'winloss' },
        { key: 'total', label: 'total' },
        // {
        //   key: 'created_at',
        //   label: 'เวลา',
        //   formatter: value => moment(value)
        //     .tz("Asia/Bangkok")
        //     .format('DD/MM/YYYY HH:mm:ss'),
        // },
      ],
      /* eslint-disable global-require */
      items: [],
      listdata: [],
      addNewDataSidebar: false,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    selected(newValue) {
      // Handle changes in individual flavour checkboxes
      if (newValue.length === 0) {
        this.indeterminate = false
        this.allSelected = false
      } else if (newValue.length === this.options.length) {
        this.indeterminate = false
        this.allSelected = true
      } else {
        this.indeterminate = true
        this.allSelected = false
      }
    },
  },
  created() {
    // setInterval(() => {
    //   this.getUserslist()
    //   this.getDeposit()
    // }, 30000)
  },
  mounted() {
    // Set the initial number of items
    // this.totalRows = this.items.length
    // this.gethistory()
  },
  methods: {
    RangDateCal() {
      // console.log(date)
      const rangArray = this.rangeDate.split(' to ')
      const start = rangArray[0]
      const end = rangArray[1]

      this.start = start
      this.end = end
      // console.log(this.start, this.end)
      // eslint-disable-next-line no-bitwise
      if (this.start && this.end) {
        this.show = true
        // this.show = false
        const params = {
          // selected: this.selected,
          start: this.start,
          end: this.end,
        }
        this.$http
          .get('https://api.legend198.com/api/history/winlose', { params })
          .then(response => {
            this.listdata = response.data.Mes
            this.show = false
          // this.onFiltered(response.data)
          })
          .catch(error => {
            console.log(error)
            this.show = false
            this.listdata = []
          })
      }
    },
    wluser(username) {
      const paramss = {
        username,
        days: this.days,
        selected: this.selected,
      }
      this.$router.push('/win-loss-user')
      localStorage.setItem('wlParams', JSON.stringify(paramss))
    },
    toggleAll(checked) {
      this.selected = checked ? this.options.slice() : []
      this.getwinloss()
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    getwinloss(days) {
      this.show = true
      // this.show = false
      const params = {
        selected: this.selected,
        days,
      }
      this.$http
        .get('https://api.legend198.com/api/history/winlose', { params })
        .then(response => {
          this.listdata = response.data.Mes
          this.rangeDate = `${response.data.sday} to ${response.data.eday}`
          this.show = false
          // this.onFiltered(response.data)
        })
        .catch(error => {
          console.log(error)
          this.show = false
          this.listdata = []
        })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      // console.log(filteredItems)
      this.items = filteredItems.Mes
      this.totalRows = filteredItems.total
    },
    addData() {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.toggleDataSidebar(true)
      this.datasidebar = {}
    },
    editData(data) {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.toggleDataSidebar(true)
      this.datasidebar = data
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val
    },
  },
}
</script>

<style scoped>
.btn-1 {
  text-align: center;
  background: transparent;
  color: #008CCF;
  border: 2px solid #03adff;
  padding: 0.5rem 1.2rem;
  border-radius: 0.358rem;
  font-weight: 700;
  width: 100%;
}
.btn-1:hover {
  box-shadow: 0 0 0 0.2rem rgba(3, 173, 255, 0.5);
  color: #008CCF;
  border: 2px solid #03adff;
  transition: 0.1s;
}
.btn-1:focus {
  color: #fff;
  font-weight: 700;
  background-image: linear-gradient(47deg, #008CCF, #03adff);
  outline: none;
}
.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 100%;
  /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.15); */
}
.styled-table thead tr {
  background-color: #2e5d92;
  color: #ffffff;
  text-align: left;
}
.styled-table th,
.styled-table td {
  padding: 12px 15px;
}
.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #2e5d92;
}
.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #2e5d92;
}
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.form-item-section {
  background-color: $product-details-bg;
}
</style>
